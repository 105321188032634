<template>
  <div class="cloud">
    <!-- 顶部图 -->
    <div class="header-banner">
      <div class="container mx-auto title-box">
        <h1 class="banner-one-title">海量权益产品任你挑选</h1>
        <h1 class="banner-two-title">批量采购 &nbsp;&nbsp; API接口 &nbsp;&nbsp; 线下采购 &nbsp;&nbsp; H5页面定制</h1>
        <button class="btn-line">获取需求</button>
      </div>
    </div>
    <!-- 开放平台服务 -->
    <div class="cloud-service">
      <div class="container mx-auto">
        <h2 class="three-leave-title">璐付云链</h2>
      </div>
      <div class="irregular-column container mx-auto">
        <CloudSeversSwiper />
      </div>
    </div>
    <!-- 提供良好的用户体验 -->
    <div class="cloud-user-experience">
      <div class="container mx-auto">
        <h2 class="three-leave-title">提供良好的用户体验</h2>
      </div>
      <div class="irregular-column container mx-auto">
        <TwoColMulRow :dataList="columnList" />
      </div>
    </div>
  </div>
</template>

<script>
// components
import CloudSeversSwiper from '@/components/common/CloudSeversSwiper';
import TwoColMulRow from '@/components/common/TwoColMulRow';

export default {
  components: { CloudSeversSwiper, TwoColMulRow },
  data() {
    return {
      columnList: [
        {
          icon: require('@/assets/img/cloud/icon-one.png'),
          bgImg: require('@/assets/img/cloud/1.jpg'),
          oneTitle: '优质货源',
          twoTitle: '',
          desc: '整合优质供应资源、品牌源头商品，全网价格最优。100%品牌产品，100%正品保障。'
        },
        {
          icon: require('@/assets/img/cloud/icon-two.png'),
          bgImg: require('@/assets/img/cloud/2.jpg'),
          oneTitle: '丰富多样',
          twoTitle: '',
          desc: '各大品类，涵盖国内外知名品牌,保障每一款商品品质和服务质量。'
        },
        {
          icon: require('@/assets/img/cloud/icon-three.png'),
          bgImg: require('@/assets/img/cloud/3.jpg'),
          oneTitle: '客户为本',
          twoTitle: '',
          desc: '关注用户需求，紧跟市场热点，每周上架新品；定制API标准接口一键轻松对接，免费获取海量商品池，为用户带来轻松、愉悦体验。'
        },
        {
          icon: require('@/assets/img/cloud/icon-fore.png'),
          bgImg: require('@/assets/img/cloud/4.jpg'),
          oneTitle: '运营售后无忧',
          twoTitle: '',
          desc: '云仓处理智能化管理体系，通过线上系统处理订单，为企业节省仓储、运营管理成本；48小时内发货，国内一线物流快递服务，全国无盲点配送，商品轻松到家，7*15小时售后服务。'
        }
      ]
    };
  },
  mounted() {
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 0, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  }
};
</script>

<style lang="less" scoped>
.cloud {
  margin-bottom: 100px;
  .header-banner {
    height: 483px;
    width: 100%;
    background-image: url('../../assets/img/cloud/cloud-bg.png');
    background-size: 100% 100%;
    background-position: center;
    background-size: center center;
    .title-box {
      padding-top: 99px;
      .banner-one-title,
      .banner-two-title {
        margin-left: 45px;
      }
      .banner-two-title {
        margin: 10px 45px;
      }
      .banner-two {
        margin: 20px 45px 0 45px;
        .btn {
          width: 97px;
          height: 34px;
          border: 1px solid @lupay-text-color-theme;
          border-radius: 17px;
          margin-right: 10px;
          color: @lupay-text-color-theme;
        }
      }
      .btn-line {
        margin-top: 80px;
        margin-left: 49px;
        border-radius: 6px;
        position: relative;
      }

      .btn-line:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  .cloud-service {
    margin-top: 70px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .irregular-column {
      margin-top: 70px;
    }
  }
  .cloud-user-experience {
    margin-top: 30px;
    .three-leave-title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      margin-left: 49px;
    }
    .irregular-column {
      margin-top: 70px;
    }
  }
  .fore-card {
    margin-top: 40px;
  }
}
</style>
