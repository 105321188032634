<template>
  <div class="cloud-news">
    <div class="flex">
      <div
        :class="[
          'cloud-news-swiper-slide',
          'flex',
          'align-end',
          index === selectIndex && 'cloud-news-swiper-slide-selected'
        ]"
        v-for="(item, index) in dataList"
        :key="index"
        @mouseenter="onChangeTransitionEnd(index)"
      >
        <img :src="item.bgImg" />
        <div class="content">
          <div class="title">{{ item.title }}</div>
          <p class="desc">{{ item.desc }}</p>
          <div class="items flex flex-wrap justify-between">
            <div class="item" v-for="(_item, _index) in item.children" :key="_index">{{ _item.title }}</div>
          </div>
        </div>
        <div class="cloud-news-swiper-slide-mask"></div>
      </div>
    </div>
  </div>
</template>

<script>
/** 璐付云链 */
export default {
  name: 'CloudSeversSwiper',
  data() {
    return {
      selectIndex: 1,
      dataList: [
        {
          title: '服务专业稳定',
          desc: '标准采购、高效专业、售后无忧',
          bgImg: require('@/assets/img/cloud/one.jpg'),
          children: [
            {
              title: '优质服务保障'
            },
            {
              title: '活动服务专属管控'
            },
            {
              title: '专业运维'
            }
          ]
        },
        {
          title: '稳定高效',
          desc: '专业技术团队潜心打造',
          bgImg: require('@/assets/img/cloud/two.jpg'),
          children: [
            {
              title: '资深设计团队打造'
            },
            {
              title: '支持API对接'
            },
            {
              title: '支持非PAI解决方案'
            },
            {
              title: '稳定专业'
            }
          ]
        },
        {
          title: '商品丰富多样',
          desc: '10万+商品满足多种需求',
          bgImg: require('@/assets/img/cloud/three.jpg'),
          children: [
            {
              title: '直连直采'
            },
            {
              title: '快速充值'
            },
            {
              title: '商品直连自由选'
            }
          ]
        }
      ]
    };
  },
  methods: {
    onChangeTransitionEnd(index) {
      this.selectIndex = index;
    }
  }
};
</script>
<style scoped lang="less">
.cloud-news {
  width: 100%;
  padding-bottom: 50px;
  .cloud-news-swiper-slide {
    height: 524px;
    cursor: pointer;
    img {
      height: 100%;
      // width: 100%;
      width: 524px;
      transform: translate(0, 0);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
      border-radius: 6px;
    }
    position: relative;
    .content {
      width: 100%;
      height: 176px;
      background-color: #102157;
      padding: 22px 44px 33px 49px;
      transform: translate(0, 0);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
      position: absolute;
      z-index: 10;
      .title {
        color: @lupay-text-color-white;
        font-size: 16px;
        margin: 5px 0;
        padding: 0 10px;
        position: relative;
      }
      .title::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 17px;
        background-color: rgba(61, 238, 228, 1);
        left: 0px;
        top: 4px;
      }
      .desc {
        color: @lupay-text-color-white;
        font-size: 14px;
        margin-left: 12px;
      }
      .items {
        .item {
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          position: relative;
          padding-left: 12px;
          margin-top: 20px;
          width: 140px;
        }
        .item:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          background-color: rgba(61, 238, 228, 1);
          left: -2px;
          top: 7px;
          border-radius: 50%;
        }
      }
    }
    .cloud-news-swiper-slide-mask {
      position: absolute;
      width: 100%;
      height: 524px;
      background: #000;
      opacity: 0.6;
      transform: translate(0, 0);
      transition-duration: 300ms;
      transition-property: transform, opacity;
      transition-timing-function: ease-in;
      filter: progid:DXImageTransform.Microsoft.alpha(opacity=50);
      z-index: 11;
    }
  }
  .cloud-news-swiper-slide:hover {
    img {
      transform: scale(1.05, 1.05);
      z-index: 200;
      border-radius: 6px;
    }
    .content {
      display: inline-block;
      opacity: 1;
      transform: translate(0px, 20px) scaleX(1.05);
      z-index: 200;
      width: 100%;
      background-color: rgba(34, 78, 219, 1);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .cloud-news-swiper-slide-mask {
      position: absolute;
      width: 100%;
      height: 524px;
      background: #000;
      opacity: 0;
      transform: translate(0, 0);
      transform: scale(1.05, 1.05);
      z-index: 201;
    }
  }
  .cloud-news-swiper-slide-selected {
    img {
      transform: scale(1.05, 1.05);
      z-index: 200;
      border-radius: 6px;
    }
    .content {
      display: inline-block;
      opacity: 1;
      transform: translate(0px, 20px) scaleX(1.05);
      z-index: 200;
      background-color: rgba(34, 78, 219, 1);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .cloud-news-swiper-slide-mask {
      position: absolute;
      width: 100%;
      height: 524px;
      background: #000;
      opacity: 0;
      transform: scale(1.05, 1.05);
      transform: translate(0, 0);
      z-index: 200;
    }
  }
}
</style>
